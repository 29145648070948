import {InfoRepository} from 'IndexedDB';
import Axios from './Axios';

const infoRepository = new InfoRepository();

export const getInfo = async (id: number) => {
    const info = (await infoRepository.getInfo(id)) as {infoText: string}[];
    let infoText;

    if (info.length == 0) {
        const response = await Axios.instance().get<{
            success: number;
            infoText: string;
            errors: string;
        }>(`cabinets/more_info/${id}`);

        if (response.data.success) {
            infoText = response.data.infoText;
            await infoRepository.addInfo([{id: id, infoText}]);
        } else throw response.data.errors;
    } else {
        infoText = info[0].infoText;
    }

    return infoText;
};

export const saveInfo = async (id: number, data: string) => {
    const response = await Axios.instance().post<{
        success: number;
        errors: string;
    }>(`cabinets/more_info/`, {
        id: id,
        text: data,
    });

    if (response.data.success) {
        await infoRepository.deleteInfo(id);
        await infoRepository.addInfo([{id: id, infoText: data}]);
    } else throw response.data.errors;
};
